(function(){
  'use strict';

  angular
  .module('app')
  .component('modalCouponBanks', {
    templateUrl: 'app/components/coupons-bank/modal-coupon-bank.html',
    controller: ModalCouponsBankController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCouponsBankController.$inject = ['toastr','_','$rootScope','user','$window','$uibModal','globalService'];

  function ModalCouponsBankController(toastr, _, $rootScope,$user,$window, $uibModal, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;


    vm.$onInit = function(){
      vm.configCoupons = vm.resolve.configCoupons;
      if(vm.resolve.couponsBank){
        vm.couponsBank = vm.resolve.couponsBank;
        vm.couponsBank.subfijo = vm.resolve.couponsBank.codes_coupon_bank[0].code.split('').slice(0,2).join('') + '...'
        vm.configCouponSelected = _.findWhere(vm.configCoupons, {id: vm.couponsBank.config_coupon})
      }
    }

    function save() {
      if(vm.couponsBank.id){
        $globalService.updateCouponBank(vm.couponsBank)
        .then(function(res){
          vm.close({$value: ''});
          $rootScope.$emit('couponsBank', {});
          $window.swal('Success',res.message,'info');
        }, function(err){
          $window.swal('Error',err.data.message,'error');
        })
      }else{
        $globalService.saveCouponBank(vm.couponsBank)
        .then(function(res){
          vm.close({$value: ''});
          $rootScope.$emit('couponsBank', {});
          $window.swal('Success',res.message,'info');
        }, function(err){
          $window.swal('Error',err.data.message,'error');
        })
      }
    }

  }
})();
