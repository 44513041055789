(function () {
  'use strict';
  angular
    .module('app')
    .directive('montoCheck', montoCheck);

  montoCheck.$inject = [];

  function montoCheck() {
    return {
      require: '?ngModel',
      scope: {
        montoCheck: '='
      },
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      if (!ctrl) {
        return;
      }
      var pw = scope.montoCheck;
      scope.$watch('montoCheck', function (newValue) {
        pw = newValue;
        ctrl.$validate();
      });
      ctrl.$validators.montoCheck = function (modelValue, viewValue) {
        return ctrl.$isEmpty(viewValue) || parseInt(pw) === parseInt(viewValue);
      };
    }
  }
})();
