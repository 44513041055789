(function () {
  'use strict';
  angular
    .module('app')
    .component('home', {
      templateUrl: 'app/components/home/home.html',
      controller: HomeController
    });

  HomeController.$inject = ['cashierService', 'toastr', 'ActionCableChannel', '$timeout', '$interval', '_', 'user', 'globalService', '$uibModal'];

  function HomeController($cashierService, toastr, ActionCableChannel, $timeout, $interval, _, $user, $globalService, $uibModal) {
    var vm = this;
    vm.loadingRequests = false;
    vm.takeRequest = takeRequest;
    vm.labels = ["Comercializadores", "Sub Agentes", "Centro de Apuestas", "Jugadores"];
    vm.data = [0, 0, 0, 0];

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
      vm.loadingRequests = true;
      $globalService.currency.query().$promise
      .then(function(res){
        vm.currencies = res;
        vm.currencySelected = res[0];
        vm.refreshData(vm.currencySelected.currency)
      })
      $cashierService.getRequests()
        .then(function (res) {
          vm.loadingRequests = false;
          vm.requests = _.map(res.request, function (data) {
            data.from_now = moment(data.created_at).fromNow();
            return data;
          });
          vm.requests_vip = _.map(res.requests_vips, function (data) {
            data.from_now = moment(data.created_at).fromNow();
            return data;
          });
          countRequest();
        })

      $globalService.getCountsUsers()
        .then(function (res) {
          vm.counts = res.counts;
          vm.data[0] = _.findWhere(res.chart, { tipo: 1 }).cantidad || 0;
          _.findWhere(res.chart, { tipo: 2 }) ? vm.data[1] = _.findWhere(res.chart, { tipo: 2 }).cantidad : 0;
          vm.data[2] = _.findWhere(res.chart, { tipo: 3 }).cantidad || 0;
          vm.data[3] = _.findWhere(res.chart, { tipo: 4 }).cantidad || 0;
        })
    }

    vm.refreshData = function () {
      $globalService.getResumenCajero({currency: vm.currencySelected.currency})
      .then(function (res) {
        vm.resumen = res.data;
      })
    }

    function takeRequest(request_id) {
      swal({
        title: 'Esta seguro de tomar la solicitud?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tomar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $cashierService.takeRequest(request_id)
              .then(function (res) {
                resolve();
                toastr.success(res.message);
                vm.sendToMyChannel(request_id);
                vm.$onInit();
                countRequest();
              }, function (err) {
                toastr.error(err.data.message);
              })
          });
        },
        allowOutsideClick: false,
      })

    }

    function countRequest() {
      vm.request_balance_count = 0;
      vm.request_withdraw_count = 0;
      _.forEach(vm.requests, function (data) {
        data.tipo ? vm.request_balance_count++ : vm.request_withdraw_count++;
      })
    }

    var channel = new ActionCableChannel("DashboardChannel", {});
    var callback = function (message) {
      switch (message.type) {
        case 'new_request':

          toastr.success('solicitud realizada', 'Nueva')
          message.data.tipo ? message.data.class = 'add-deposit' : message.data.class = 'add-withdraw';
          if (message.data.pre_approved) {
            vm.requests_vip.push(message.data);
          } else {
            vm.requests.push(message.data);
          }
          countRequest();
          if (message.data.pre_approved) {
            $timeout(function () {
              var new_request = _.findWhere(vm.requests_vip, { id: message.data.id });

              new_request.from_now = moment(new_request.created_at).fromNow();
              new_request.class = null;
            }, 1000)
          } else {
            $timeout(function () {
              var new_request = _.findWhere(vm.requests, { id: message.data.id });

              new_request.from_now = moment(new_request.created_at).fromNow();
              new_request.class = null;
            }, 1000)
          }
          break;
        case 'remove_request':
          var request = _.findWhere(vm.requests, { id: message.request_id });
          request['class'] = 'taken';
          if (request) {
            if (request.pre_approved) {
              $timeout(function () {
                vm.requests_vip = _.without(vm.requests_vip, request);
                countRequest();
              }, 1000)
            } else {
              $timeout(function () {
                vm.requests = _.without(vm.requests, request);
                countRequest();
              }, 1000)
            }
          }
          break;
        case 'update_request':
          var request = _.findWhere(vm.requests_vip, { id: message.request_id });
          request.referencia = message.referencia
          request.class = 'update-deposit';
          $timeout(function () {
            request.class = null;
          }, 1000)

          break;
      }
    };
    channel.subscribe(callback)
      .then(function () {
        vm.sendToMyChannel = function (message) {
          channel.send(message);
        };
      });


    vm.showRequestsEffecty = function (data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalEffecty',
        windowClass: 'createLoteriasComponent',
        backdrop: false,
        size: 'lg',
        resolve: {
          currencies: function () {
            return vm.currencies;
          },
          currencySelected: function () {
            return vm.currencySelected;
          },
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        toastr.error('Accion Cancelada');
      });
    }

    vm.showUser = function (request) {
      swal({
        type: 'info',
        html: '<h3 style="margin-top:0px">' + request.usuario_envia_info.nombre + '</h3>' + '<h3 style="margin-top:0px">' + request.usuario_envia_info.representante + '</h3>',
        title: 'Usuario'
      })
    }

  }


})();
