(function(){
  'use strict';
  angular
  .module('app')
  .component('configCoupons', {
    templateUrl: 'app/components/config-coupons/config-coupon.html',
    controller: ConfigCouponsController
  });

  ConfigCouponsController.$inject = ['globalService','toastr', '$uibModal','$rootScope']

  function ConfigCouponsController($globalService, toastr, $uibModal, $rootScope) {
    var vm = this;
    vm.newConfigCoupon = newConfigCoupon;
    vm.editar = editar;

    vm.$onInit = function(){
      $globalService.getConfigCoupons()
      .then(function(res){
        vm.configCoupons = res;
      })

    }

    function newConfigCoupon(){

      var modal = $uibModal.open({
        animation: true,
        component: 'modalCoupons',
        size: 'md',
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function editar(config){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalCoupons',
        size: 'md',
        resolve: {
          configCoupon: function(){
            return angular.copy(config)
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }


    $rootScope.$on('configCoupons', function(event, data){
      vm.$onInit();
    })

  }

})();
