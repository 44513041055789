(function(){
  'use strict';

  angular
  .module('app')
  .component('modalRequestsVerify', {
    templateUrl: 'app/components/contraloria/verify-request.html',
    controller: ModalAcceptRequestVerify,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalAcceptRequestVerify.$inject = ['toastr','_','$rootScope','user','cashierService','$window','$uibModal','globalService'];

  function ModalAcceptRequestVerify(toastr, _, $rootScope,$user, $cashierService, $window, $uibModal, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.viewImage = viewImage;

    vm.$onInit = function(){
      vm.request = vm.resolve.request;
      vm.type_request = vm.resolve.type_request;
    }

    function save() {
      $globalService.verifyRequest(vm.request)
      .then(function(res){
        vm.close({$value: ''});
        $window.swal('Success',res.message,'info');
      }, function(err){
        $window.swal('Error',err.data.message,'error');
      })
    }

    function viewImage(){
      if(vm.request.file.url != null){
        var modal = $uibModal.open({
          animation: true,
          component: 'modalImage',
          size: 'lg',
          resolve: {
            request: function(){
              return vm.request;
            }
          }
        });

        modal.result.then(function(res){
        }, function(err){

        });
      }else{
        $window.swal('Esta solicitud no posee imagen','','info');
      }
    }
  }
})();
