(function () {
  'use strict';
  angular
  .module('app')
  .factory('user', user);

  user.$inject = ['$window', '$rootScope'];

  function user ($window, $rootScope) {
    var currentUser;

    if ($window.localStorage.hasOwnProperty('user')) {
      currentUser = JSON.parse($window.localStorage.getItem('user'));
    }

    return {
      getCurrentUser: getCurrentUser,
      setCurrentUser: setCurrentUser,
    };

    function getCurrentUser() {
      return currentUser;
    }

    function setCurrentUser(user) {
      if (currentUser && currentUser.token && user && !user.token) {
        user.token = currentUser.token;
      }
      currentUser = user;
      $window.localStorage.user = JSON.stringify(user);
      $rootScope.$broadcast('updatedUser', user);
    }
  }
})();
