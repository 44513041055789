(function () {
  'use strict';
  angular
    .module('app')
    .directive('referenciaCheck', referenciaCheck);

  referenciaCheck.$inject = [];

  function referenciaCheck() {
    return {
      require: '?ngModel',
      scope: {
        referenciaCheck: '='
      },
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      if (!ctrl) {
        return;
      }
      var pw = scope.referenciaCheck;
      scope.$watch('referenciaCheck', function (newValue) {
        pw = newValue;
        ctrl.$validate();
      });
      ctrl.$validators.referenciaCheck = function (modelValue, viewValue) {
        return ctrl.$isEmpty(viewValue) || pw === viewValue;
      };
    }
  }
})();
