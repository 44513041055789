(function(){
  'use strict';
  angular
  .module('app')
  .component('reportCoupons', {
    templateUrl: 'app/components/report-coupons/report-coupons.html',
    controller: ReportCouponsController
  });

  ReportCouponsController.$inject = ['globalService','toastr', '$uibModal','$rootScope']

  function ReportCouponsController($globalService, toastr, $uibModal, $rootScope) {
    var vm = this;


    vm.$onInit = function(){
      $globalService.getReportCoupons()
      .then(function(res){
        vm.reportCoupons = res;
        //console.log(res)
      })
    }


    vm.getReport = function(){
    }

    $rootScope.$on('configCoupons', function(event, data){
      vm.$onInit();
    })

  }

})();
