(function(){
  'use strict';

  angular
  .module('app')
  .component('codeComponent', {
    templateUrl: 'app/components/code/code.html',
    controller: ModalCodeControler,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCodeControler.$inject = ['toastr','codeService','user','$window'];

  function ModalCodeControler(toastr,$codeService,user, $window) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.showAgain = false;
    vm.getAgain = getAgain;

    vm.$onInit = function(){
      var data = {
        usuario_id: user.getCurrentUser().id,
        type_code: vm.resolve.type_code
      }
    }

    function save() {
      vm.send = true;
      $codeService.validateCodeFromApp(vm.code)
      .then(function(res){
        vm.send = false;
        vm.close({$value: 'exito'});
      }, function(err){
        toastr.error('Codigo Invalido');
        vm.send = false;
        vm.showAgain = true;
      })
    }

    function getAgain(){
      vm.$onInit();
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
