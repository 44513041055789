angular
  .module('app',[
  'ui.router',
  'slick',
  'ngAnimate',
  'ngTouch',
  'ngResource',
  'ngSanitize',
  'ui.bootstrap',
  'toastr',
  'ngActionCable',
  'ui.select',
  'ui.checkbox',
  'ui.bootstrap.datetimepicker',
  'chart.js',
  'angular-ladda',
  'ngMask'
]);
