angular
.module('app')
.component('webLayout', {
  templateUrl: 'app/components/layouts/web-layout.html',
  controller: WebLayoutController
});

function WebLayoutController() {

}
