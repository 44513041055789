(function(){
  'use strict';

  angular
  .module('app')
  .component('editUser', {
    templateUrl: 'app/components/users/createUser.html',
    controller: ModalCreateUser,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateUser.$inject = ['toastr','userService','_','$rootScope'];

  function ModalCreateUser(toastr, $userService, _, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.checkAll = checkAll;
    vm.uncheckAll = uncheckAll;
    vm.send = false;
    vm.activeTap = 0;

    setTimeout(function(){
      vm.user = vm.resolve.user;
      _.map(vm.user.permissions, function(data){
        data.name = data.section.name;
        data.pretty_name = data.section.pretty_name;
        return data
      })
      //console.log(vm.user.permissions)
    },0);



    function save() {
      vm.send = true;
      // _.forEach(vm.user.permissions, function(permiso){
      //   delete permiso.name;
      //   delete permiso.pretty_name;
      // })
      $userService.updateUser(vm.user.id, vm.user)
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
        vm.close({$value: res.data});
      }, function(err){
        toastr.error('Hubo un error');
      })
    }

    function checkAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = true;
      })
    }

    function uncheckAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = false;
      })
    }

    vm.next = function(action){
      if(action == 'next'){
        if(vm.activeTap == 0){
          vm.activeTap = 1;
        }
      }else{
        vm.activeTap = 0;
      }
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
