(function(){
  'use strict';

  angular
  .module('app')
  .component('modalImage', {
    templateUrl: 'app/components/modals/modal-image.html',
    controller: ModalImage,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalImage.$inject = ['toastr','_','$rootScope','user','cashierService'];

  function ModalImage(toastr, _, $rootScope,$user, $cashierService) {
    var vm = this;
    vm.send = false;

    vm.$onInit = function(){
      vm.request = vm.resolve.request;
    }

  }
})();
