angular
  .module('app')
  .component('user', {
    templateUrl: 'app/components/users/users.html',
    controller: UsersController
  });

  UsersController.$inject = ['userService','$rootScope','$uibModal', 'toastr','permissionsService'];

function UsersController($userService, $rootScope, $uibModal, toastr, $permissionsService) {
  var vm = this;
  vm.createUser = createUser;
  vm.destroy = destroy;
  vm.edit = edit;

  function init(){
    $userService.getUsers()
    .then(function(res){
      vm.users = res;
    })
    $permissionsService.getModules()
    .then(function(res){
      vm.sections = res;
    })
  }
  init();

  function createUser(){
    var modal = $uibModal.open({
      animation: true,
      component: 'createUser',
      size: 'lg',
      resolve: {
        sections: function(){
          return angular.copy(vm.sections)
        }
      }
    });

    modal.result.then(function(res){
      vm.users.push(res)
    }, function(err){
      toastr.error('Accion cancelada');
    });
  }

  function edit(user) {
    var modal = $uibModal.open({
      animation: true,
      component: 'editUser',
      size: 'lg',
      resolve: {
        user: function(){
          return angular.copy(user)
        },
        sections: function(){
          return angular.copy(vm.sections)
        }
      }
    });

    modal.result.then(function(res){
    }, function(err){
      toastr.error('Accion cancelada');
    });
  }

  function destroy(user, index) {
    swal({
      title: 'Confirmar borrado',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      showLoaderOnConfirm: true,
      preConfirm: function() {
        return new Promise(function(resolve, reject) {
          $userService.deleteUser(user.id)
          .then(function() {
            vm.users.splice(index, 1)
            resolve();
          }, function() {
            reject('Ha ocurrido un error');
          });
        });
      },
      allowOutsideClick: false,
    }).then(function() {
      swal(
        'Borrado!',
        'Ha sido borrado con éxito.',
        'success'
      );
    });
  }



  $rootScope.$on('users', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

}
