(function(){
  'use strict';
  angular
  .module('app')
  .component('asideBar', {
    templateUrl: 'app/components/aside-bar/aside-bar.html',
    controller: AsideBarController
  });

  AsideBarController.$inject = ['user'];

  function AsideBarController($user) {
    var vm = this;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
    }

  }

})();
