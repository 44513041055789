(function(){
  'use strict';
  angular
    .module('app')
    .component('aproveAutomatic', {
      templateUrl: 'app/components/aprove-automatic/aprove-automatic.html',
      controller: AproveAutomaticController
    });

    AproveAutomaticController.$inject = ['cashierService','$scope', 'ActionCableChannel', '$timeout', '$interval', '_','user','globalService']

  function AproveAutomaticController($cashierService, $scope, ActionCableChannel, $timeout, $interval, _,$user, $globalService) {
    var vm = this;
    vm.loadingRequests = true;

    vm.$onInit = function(){
      $cashierService.getRequests()
      .then(function(res){
        vm.loadingRequests = false;
        vm.requests = _.map(res, function(data){
          data.from_now = moment(data.created_at).fromNow();
          return data;
        });
      })
    }

    vm.application = {
      customDataString: ""
    };

    vm.validateJSON = function() {
      var copyCustomData = vm.application.customDataString;
      try {
        JSON.parse(copyCustomData);
        $scope.myForm.customData.$valid = true;
      } catch (e) {
        console.log($scope.myForm)
        $scope.myForm.customData.$valid = false;
      }
      return true;
    }

    vm.sendData = function(){
      vm.loadingRequests = true;
      
      $globalService.sendDataAutomatic({data: JSON.parse(vm.application.customDataString)})
      .then(function(res){
        vm.loadingRequests = false;
        vm.application.customDataString = "";
        Swal({
          title: 'Exito',
          text: 'Solicitudes Aprobadas: ' + res.solicitudes ,
          type: 'success',
        })
      },function(err){
        vm.application.customDataString = "";
        vm.loadingRequests = false;
        Swal({
          title: 'Error',
          text: err.data.error,
          type: 'warning'
        })
      })
    }


  }


})();
