angular
  .module('app')
  .component('games', {
    templateUrl: 'app/components/games/games.html',
    controller: GamesController
  });

  GamesController.$inject = ['$rootScope','$uibModal', 'toastr','globalService'];

function GamesController($rootScope, $uibModal, toastr, $globalService) {
  var vm = this;
  vm.loading = false;


   vm.$onInit = function(){
     vm.loading = true;
     $globalService.getGames()
     .then(function(res){
       vm.loading = false;
       vm.games = res;
     })
  }

  vm.toggleAvailability = function(game){
    vm.loading = true;
    var data = angular.copy(game)
    data.available = !data.available;
    swal({
        title: 'Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.updateGame(data)
            .then(function(res){
              resolve();
              vm.loading = false;
              game.available = !game.available;
              Swal('Exito', res.message, 'success');
            }, function(err){
              vm.loading = false;
              Swal('Error', err.data.message, 'error');
            })
          });
        },
        allowOutsideClick: false,
      })
      .then(function(result){

      })
  }

  $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

}
