(function () {
  angular
  .module('app')
  .factory('codeService', codeService);
})();

codeService.$inject = ['$http', 'url','user'];

function codeService($http, $url, $user) {

  function validateCodeFromApp(data){
    return $http.post($url.getApiUrl('/miscelaneos/validate_security_code'), data)
    .then(function(res){
      return res.data;
    })
  }



  return {
    validateCodeFromApp: validateCodeFromApp
  };
}
