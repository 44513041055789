(function(){
  'use strict';
  angular
  .module('app')
  .component('requestBalance', {
    templateUrl: 'app/components/balance-request/balance-request.html',
    controller: BalanceRequestController
  });

  BalanceRequestController.$inject = ['cashierService','toastr','$uibModal']

  function BalanceRequestController($cashierService, toastr, $uibModal) {
    var vm = this;
    vm.approveRequest = approveRequest;
    vm.rejectRequest = rejectRequest;

    vm.$onInit = function(){
      $cashierService.getMyRequests(1)
      .then(function(res){
        vm.requests = res.request;
        vm.requests = _.union(vm.request, res.requests_vips);
      })
    }

    function approveRequest(request){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalRequests',
        size: 'lg',
        resolve: {
          type_request: function(){
            return 1;
          },
          request: function(){
            return request;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function rejectRequest(request_id){
      swal({
        title: 'Esta seguro de cancelar la solicitud?',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Rechazar',
        showLoaderOnConfirm: true,
        preConfirm: function(reason){
          var data = {
            detalle: reason,
            tipo: 3
          }
          return $cashierService.rejectRequestBalance(request_id, data)
          .then(function(res){
            return res
          }, function(err){
            swal.showValidationError(err.data.message)
          })
        }
      }).then(function(result){
        if(result.value){
          toastr.success(result.value.message);
          vm.$onInit();
        }else{
          toastr.error('Accion cancelada');
        }
      })
    }

  }
})();
