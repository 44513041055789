angular
  .module('app')
  .component('customers', {
    templateUrl: 'app/components/manage-customers/customers.html',
    controller: ManageCustomersController
  });

  ManageCustomersController.$inject = ['$rootScope','$uibModal', '$state','globalService','user','$stateParams','$window'];

function ManageCustomersController($rootScope, $uibModal, $state, $globalService,$user, $stateParams, $window) {
  var vm = this;
  vm.managment = managment;
  vm.sendMssage = sendMssage;
  vm.sendMssageAllCustomers = sendMssageAllCustomers;
  vm.currentUser = $user.getCurrentUser();
  vm.consultTipoEstructuras = consultTipoEstructuras;
  vm.tipo_estructuras = [
    {tipo: 1, name: "Agente"},
    {tipo: 2, name: "SubAgente"},
    {tipo: 3, name: "Agencia"},
    {tipo: 4, name: "Jugador"},
    {tipo: 5, name: "Punto de venta"}
  ];

   vm.$onInit = function(){
     vm.loading = true;
    if(eval($stateParams.plays_today)){
      vm.plays_today = true;
      vm.tipo = parseInt($stateParams.tipo_estructura);
      $globalService.getCustomerPlays({tipo_estructura: $stateParams.tipo_estructura, plays_today: $stateParams.plays_today})
      .then(function(res){
        vm.loading = false;
        vm.estructuras = _.map(res, function(data){
          data.new = false
          var created = moment(data.created_at)
          if(moment().diff(created, 'days') == 0){
            data.new = true
          }
          return data;
        });
      })
    }else{
      vm.plays_today = false;
      vm.tipo = null;
      $globalService.getCustomers()
      .then(function(res){
        vm.loading = false;
        vm.estructuras = _.map(res, function(data){
          data.new = false
          var created = moment(data.created_at)
          if(moment().diff(created, 'days') == 0){
            data.new = true
            console.log(data)
          }
          return data;
        });
      })
    }
  }

  vm.toggleStructuras = function(data){
    if(_.isNull(data)){
      $stateParams = {};
      $window.location = '/administrar-jugadores/';
      vm.plays_today = false;
    }else{
      $stateParams = {tipo: data, plays_today: true}
      $window.location = '/administrar-jugadores/'+data+'?plays_today=true';
      vm.plays_today = true;
    }
    vm.$onInit();
  }

  vm.lockUsers = function(data){
    swal({
      title: 'Indique el motivo de bloqueo.',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Bloquear',
      showLoaderOnConfirm: true,
      preConfirm: function(reason) {
        data.motivo = reason
        return $globalService.lockUsers(data)
        .then(function(res){
          return res;
        }, function(err){
          swal.showValidationError(err.data.message);
        })
      }
    }).then(function(result){
      if(result.value){
        swal('info',result.value.message,'info');
        vm.$onInit();
      }
    })    
  }

  vm.unLockusers = function(data){
    $globalService.unLockUsers(data)
    .then(function(res){
      swal('info', res.message, 'info')
      vm.$onInit();
    },function(err){
      swal('warning', res.data.message, 'info')
    })
  }

  function managment(estructura){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalCajeroCustomers',
      size: 'lg',
      backdrop: false,
      resolve: {
        estructura: function(){
          return angular.copy(estructura)
        }
      }
    });

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  function sendMssage(estructura){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalSendEmail',
      size: 'lg',
      resolve: {
        estructura: function(){
          return angular.copy(estructura)
        }
      }
    });

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  function sendMssageAllCustomers(){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalSendEmailAllCustomers',
      size: 'lg',
    });

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  function consultTipoEstructuras(){
    console.log(vm.tipoCustomerSelected)
    $globalService.getCustomers(vm.tipoCustomerSelected.tipo)
    .then(function(res){
      vm.estructuras = res;
    })
  }


  


  $rootScope.$on('estructuras', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

}
