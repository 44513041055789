angular
.module('app')
.component('newAgent', {
  templateUrl: 'app/components/manage-agents/new-agent.html',
  controller: NewAgentController,
})

NewAgentController.$inject = ['$window', 'user', 'globalService'];

function NewAgentController($window, $user, $globalService){
  var vm = this;
  vm.structure = {
    permit_credito: false,
    conf_cajero_web_creditos_attributes: []
  }
  
  vm.$onInit = function(){
    $globalService.currency.query().$promise
    .then(function(res){
      vm.currencies = res;
    })
    $globalService.configEstructuraMarketer()
    .then(function(res){
      vm.structure.configuracion_estructura_attributes = res;
    })
    vm.currentUser = $user.getCurrentUser();
  }

  vm.save = function(){
    console.log(vm.structure)
    vm.send = true;
    $globalService.saveAgent(vm.structure)
    .then(function(res){
      vm.send = false;
      $window.swal('Agente registrado exitosamente',res.message, 'success');
      vm.close({$value: 'ok'});
    }, function(err){
      vm.send = false;
      vm.dismiss({$value: 'cancel'});
      $window.swal('Error!',err.data.message,'error');
    })
    
  }

  vm.setConfCajeroWebCreditos = function(){
    if(vm.structure.permit_credito){
      vm.structure.conf_cajero_web_creditos_attributes = _.reduce(vm.currencies, function(memo, data){
        memo.push({
          moneda_id: data.id,
          currency: data.currency,
          amount: 0
        })
        return memo;
      },[])
    }else {
      vm.structure.conf_cajero_web_creditos = [];
    }
  }

}
