angular
.module('app')
.component('gModalTicket', {
  templateUrl: 'app/components/global-modals/modal-ticket.html',
  controller: GModalTicketController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

GModalTicketController.$inject = ['$window','user'];

function GModalTicketController($window,$user){
  var vm = this;
  vm.cancel = cancel;
  vm.copyClipboard = copyClipboard;

  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.ticket = vm.resolve.ticket;
    vm.ticket_print = vm.resolve.ticket_print;
    vm.sendTicket = sendTicket;
    vm.ticket_ws = vm.resolve.ticket_ws;
  }

  vm.print = function(){
    vm.dismiss({$value: 'cancel'});
    return $window.location.href = 'mparlay:'+vm.ticket_print;
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  function sendTicket(){
    var newTicket = vm.ticket_ws.split('<br>');
    var ticket = newTicket.join('%0a');
    
    if(window.mobileAndTabletcheck()){
      $window.location = "https://api.whatsapp.com/send?phone=+58"+vm.phone+"&text="+ticket;
    }else{
      window.open("https://web.whatsapp.com/send?phone=+58"+vm.phone+"&text="+ticket,'_blank')
    }
  }

  function copyClipboard(){
    var newTicket = vm.ticket_ws.split('<br>');
    var ticket = newTicket.join('\n');

    var el = document.createElement('textarea');
    el.value = ticket;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    $window.swal('Texto Copiado','Ticket copiado en el portapapeles con exito','info');
    
  }

}
