(function(){
  'use strict';

  angular
  .module('app')
  .component('cintillo', {
    templateUrl: 'app/components/cintillo/cintillo.html',
    controller: CintilloControllerController
  })

  CintilloControllerController.$inject = ['toastr','$rootScope','globalService','_','$window'];


  function CintilloControllerController(toastr, $rootScope, $globalService, _, $window) {
    var vm = this;
    vm.loading = true;
    
    
    vm.$onInit = function () {
      $globalService.getCintillo()
      .then(function(res){
        vm.cintillo = res;
        if(vm.cintillo){
          vm.cintillo.expire_at = new Date(vm.cintillo.expire_at);
        }
        vm.loading = false;
      });
    }

    vm.save = function(){
      vm.send = true;
      $globalService.saveCintillo(vm.cintillo)
      .then(function(res){
        vm.send = false;
        swal('Guardado', 'Se ha guardado el cintillo', 'success');
      });
      
    }

    $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Orden de los sorteos para la taquilla',previewPage: 'Dashboard', currentPage:'Loteria'}});

  }





})();
