(function(){
  'use strict';

  angular
    .module('app')
    .component('accountsMovilPay', {
      templateUrl: 'app/components/accounts-movil-pay/accounts-movil-pay.html',
      controller: AccountsMobilesPayController
    });

    AccountsMobilesPayController.$inject = ['accountService','$rootScope','$uibModal', 'toastr'];

  function AccountsMobilesPayController($accountService, $rootScope, $uibModal, toastr) {
    var vm = this;
    vm.accounts = [];
    vm.create = create;
    vm.deleteBank = deleteBank;
    vm.edit = edit;

    vm.$onInit = function(){
      $accountService.getAccounts()
      .then(function(res){
        vm.accounts = res;
      })
    }

    function create(mobile) {
      var modal = $uibModal.open({
        animation: true,
        component: 'createAccounts',
        size: 'lg',
        resolve: {
          mobile: function(){
            return angular.copy(mobile)
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function edit(account) {
      var modal = $uibModal.open({
        animation: true,
        component: 'createAccounts',
        size: 'lg',
        resolve: {
          account: function(){
            return angular.copy(account)
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function deleteBank(index, banco){
      swal({
        title: 'Confirmar borrado',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $accountService.deletedddAccount(banco.id)
            .then(function() {
              vm.accounts.splice(index, 1)
              resolve();
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      });
    }


    $rootScope.$on('accounts', function(event, data){
      vm.$onInit();
    })



    $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

  }

})();
