(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadTree', loadTree);

  loadTree.$inject = ['$state','$timeout'];

  function loadTree($state, $timeout) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).click(function(){
        $(el).toggleClass('menu-open');
        if(el.hasClass('menu-open')){
          var ul = $(el).find('ul.treeview-menu')
          ul.slideDown("slow")
        }else{
          var ul = $(el).find('ul.treeview-menu')
          ul.slideUp("slow")
        }
      });
    }
  }
})();
