(function(){
  'use strict';
  angular
  .module('app')
  .component('vipclients', {
    templateUrl: 'app/components/vip-clients/vip-clients.html',
    controller: VipClientsController
  });

  VipClientsController.$inject = ['cashierService','toastr', '$uibModal','$rootScope']

  function VipClientsController($cashierService, toastr, $uibModal, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.newVipClient = newVipClient;
    vm.removeFromVips =  removeFromVips;


    vm.$onInit = function(){
      $cashierService.getVipClients()
      .then(function(res){
        vm.clients = res;
      })

    }

    function newVipClient(){

      var modal = $uibModal.open({
        animation: true,
        component: 'clients',
        size: 'lg',
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }


    function save(){
      swal({
        title: 'Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $cashierService.saveSettingsDefault(vm.setting)
            .then(function(res){
              resolve();
              toastr.info(res.message)
            }, function(err){
              reject();
              toastr.error(err.data.message);
            })
          });
        },
        allowOutsideClick: false,
      })
    }

    function removeFromVips(data){
      swal({
        title: 'Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $cashierService.removeFromVip(data.id)
            .then(function(res){
              resolve();
              vm.$onInit();
              toastr.info(res.message)
            }, function(err){
              reject();
              toastr.error(err.data.message);
            })
          });
        },
        allowOutsideClick: false,
      })
    }


    $rootScope.$on('vipclients', function(event, data){
      vm.$onInit();
    })

  }

})();
