(function () {
  'use strict';
  angular
  .module('app')
  .factory('reportsService', reportsService);

  reportsService.$inject = ['$http', 'url'];

  function reportsService($http, $url) {

    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getReportSales(data){
      return $http.get($url.getApiUrl('reports/reporte_ventas'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getReportCajeroWebCredito(data){
      return $http.get($url.getApiUrl('reports/reporte_cajer_web_credito_detail'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    

    // function getNumbers(data){
    //   return $http.get($url.getApiUrl('reports/mas_vendidos'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }
    //
    // function getReportAwardTime(data){
    //   return $http.get($url.getApiUrl('reports/report_hora_premiacion'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }
    //
    // function getReportSalesLoteryMarketer(data){
    //   return $http.get($url.getApiUrl('reports/report_for_loteria'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }
    // function getReportByLoteryFromMarketers(data){
    //   return $http.get($url.getApiUrl('reports/report_comercializador_loteria'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }
    // function getReportByLoteryByDraws(data){
    //   return $http.get($url.getApiUrl('reports/report_sorteo_loteria'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }
    // function getReportResultQuintico(data){
    //   return $http.get($url.getApiUrl('reports/consulta_resultados_quintico'+hashToParams(data)))
    //   .then(function(res){
    //     return res.data;
    //   })
    // }





    return {
      getReportSales: getReportSales,
      getReportCajeroWebCredito: getReportCajeroWebCredito
      // getNumbers: getNumbers,
      // getReportAwardTime: getReportAwardTime,
      // getReportSalesLoteryMarketer: getReportSalesLoteryMarketer,
      // getReportByLoteryFromMarketers: getReportByLoteryFromMarketers,
      // getReportByLoteryByDraws: getReportByLoteryByDraws,
      // getReportResultQuintico: getReportResultQuintico

    };
  }
})();
