(function(){
  'use strict';

  angular
  .module('app')
  .component('modalMangementAgents', {
    templateUrl: 'app/components/manage-agents/manage-agents.html',
    controller: ModalMangementAgents,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalMangementAgents.$inject = ['toastr','globalService','_','$rootScope'];

  function ModalMangementAgents(toastr, $globalService, _, $rootScope) {
    var vm = this;

    vm.$onInit = function(){
      vm.estructura = vm.resolve.estructura;
    }

    vm.verifyAgent = function(){
      swal({
          title: 'Esta seguro de verificar el agente?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {

            return new Promise(function(resolve, reject) {
              $globalService.verifyAgent(vm.estructura)
              .then(function(res){
                resolve();
                toastr.success(res.message);
                vm.close();
              }, function(err){
                toastr.error(err.data.message);
              })
            });
          },
          allowOutsideClick: false,
        })
    }


    vm.suspendAgent = function(){
      swal({
        title: 'Motivo por el cual desea suspender al Comercializador',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Suspender',
        showLoaderOnConfirm: true,
        preConfirm: function(reason){
          var data = {
            id: vm.estructura.id,
            motivo: reason,
          }
          return $globalService.suspendAgent(data)
          .then(function(res){
            return res
          }, function(err){
            swal.showValidationError(err.data.message)
          })
        }
      }).then(function(result){
        if(result.value){
          toastr.success(result.value.message);
          vm.close();
        }else{
          toastr.error('Accion cancelada');
        }
      })

    }








  }
})();
