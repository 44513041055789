(function(){
  'use strict';

  angular
  .module('app')
  .component('clients', {
    templateUrl: 'app/components/vip-clients/clients.html',
    controller: ModalClientsController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalClientsController.$inject = ['toastr','_','$rootScope','user','cashierService'];

  function ModalClientsController(toastr, _, $rootScope,$user, $cashierService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;

    vm.$onInit = function(){
      $cashierService.getNoVipClients()
      .then(function(res){
        //console.log(res)
        vm.clients = res;
      })
      $cashierService.getSettingsDefault()
      .then(function(res){
        vm.setting = res;
      })
    }

    function save() {
      vm.send = true;
      var newCajerosVip = _.reduce(vm.clients, function(memo, data){
        if(data.monto_vip > 0){
          memo.push({
            id: data.id,
            monto_vip: data.monto_vip
          })
        }
        return memo;
      },[])


        $cashierService.saveNewVipClients({vips: newCajerosVip})
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          $rootScope.$emit('vipclients',{});
          vm.close();
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        })

    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
