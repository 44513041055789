(function(){
  'use strict';

  angular
  .module('app')
  .filter('getNameBank', getNameBank);

  function getNameBank(){

    return function(status){
      return {
        '0156': '100% BANCO',
        '0196': 'ABN AMRO BANK',
        '0172': 'BANCAMIGA BANCO MICROFINANCIERO, C.A.',
        '0171': 'BANCO ACTIVO BANCO COMERCIAL, C.A.',
        '0166': 'BANCO AGRICOLA',
        '0175': 'BANCO BICENTENARIO',
        '0128': 'BANCO CARONI, C.A. BANCO UNIVERSAL',
        '0164': 'BANCO DE DESARROLLO DEL MICROEMPRESARIO',
        '0102': 'BANCO DE VENEZUELA S.A.I.C.A.',
        '0114': 'BANCO DEL CARIBE, C.A.',
        '0149': 'BANCO DEL PUEBLO SOBERANO, C.A.',
        '0163': 'BANCO DEL TESORO',
        '0176': 'BANCO ESPIRITO SANTO, S.A.',
        '0115': 'BANCO EXTERIOR, C.A.',
        '0003': 'BANCO INDUSTRIAL DE VENEZUELA.',
        '0173': 'BANCO INTERNACIONAL DE DESARROLLO, C.A.',
        '0105': 'BANCO MERCANTIL, C.A.',
        '0191': 'BANCO NACIONAL DE CREDITO',
        '0116': 'BANCO OCCIDENTAL DE DESCUENTO.',
        '0138': 'BANCO PLAZA',
        '0108': 'BANCO PROVINCIAL BBVA',
        '0104': 'BANCO VENEZOLANO DE CREDITO, S.A.',
        '0168': 'BANCRECER S.A. BANCO DE DESARROLLO',
        '0177': 'BANFANB',
        '0146': 'BANGENTE',
        '0174': 'BANPLUS BANCO COMERCIAL, C.A',
        '0190': 'CITIBANK',
        '0121': 'CORP BANCA.',
        '0157': 'DELSUR BANCO UNIVERSAL',
        '0151': 'FONDO COMÚN',
        '0601': 'INSTITUTO MUNICIPAL DE CRÉDITO POPULAR',
        '0169': 'MIBANCO BANCO DE DESARROLLO, C.A.',
        '0137': 'SOFITASA',
        '0134': 'BANESCO BANCO UNIVERSAL',
		  }[status];
    }

  }
})();
