(function(){
  'use strict';

  angular
  .module('app')
  .component('emailNotifications', {
    templateUrl: 'app/components/emails-notifications/email-notifications.html',
    controller: EmailsNotificationsController,
  });

  EmailsNotificationsController.$inject = ['toastr','_','$rootScope','$window','globalService','$uibModal'];

  function EmailsNotificationsController(toastr, _, $rootScope,$window, $globalService, $uibModal) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.loading = true;
      $globalService.getEmailsNotifications()
      .then(function(res){
        vm.loading = false;
        vm.notifications = _.map(res, function(data){data.update = false;return data;});
      })
    }

    vm.toggleStatus = function(data){
      vm.loading = true;
      $globalService.toggleStatusEmailsNotifications(data)
      .then(function(res){
        vm.loading = false;
        data.active = !data.active;
        $window.swal('Exito', res.message, 'success');
      }, function(err){
        vm.loading = false;
        $window.swal('Error', err.data.message, 'error');
      })
    }

    vm.edit = function(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalEmailNotifications',
        size: 'lg',
        resolve: {
          notification: function(){
            return angular.copy(data);
          }
        }
      });
    }



  }
})();
