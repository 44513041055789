(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadAdminLte', loadAdminLte);

  loadAdminLte.$inject = [];

  function loadAdminLte() {
    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      window.loadAdminLTE();
    }
  }
})();
