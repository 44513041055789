angular
.module('app')
.component('loginLayout', {
  templateUrl: 'app/components/layouts/login-layout.html',
  controller: loginLayoutController
});

function loginLayoutController() {

}
