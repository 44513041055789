(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettyAmount', prettyAmount)

  function prettyAmount(accounting){

    return function(amount, currency){
      return accounting.formatMoney(amount, currency + ' ','2','.',',');
    }
  }

})();
