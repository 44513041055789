(function(){
  'use strict';

  angular
  .module('app')
  .component('contraloria', {
    templateUrl: 'app/components/contraloria/contraloria.html',
    controller: ContraloriaController,
  });

  ContraloriaController.$inject = ['toastr','_','$rootScope','user','globalService','$uibModal'];

  function ContraloriaController(toastr, _, $rootScope,$user, $globalService, $uibModal) {
    var vm = this;

    vm.$onInit = function(){
      $globalService.getRequestNoVerify({today: moment().format('YYYY-MM-DD')})
      .then(function(res){
        vm.requests = _.map(res, function(data){
          data.usuario = data.usuario_envia_info.nombre;
          data.origin = data.name_banks.pretty_banco_origin;
          data.receptor = data.name_banks.pretty_banco_receptor;
          return data;
        });

        vm.total = _.reduce(vm.requests, function(memo, data){
          if(data.tipo == 1){
            memo.deposito += parseFloat(data.monto);
          }else{
            memo.retiro += parseFloat(data.monto);
          }
          return memo;
        },{retiro: 0, deposito: 0});
      })
    }

    vm.verify = function(request, index){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalRequestsVerify',
        size: 'lg',
        resolve: {
          type_request: function(){
            return request.tipo;
          },
          request: function(){
            return request;
          }
        }
      });

      modal.result.then(function(){
        vm.requests.splice(index, 1)
      });
    }

    vm.getReport = function(){
      $globalService.getRequestNoVerify({from: moment(vm.from).format('YYYY-MM-DD'), to: moment(vm.to).format('YYYY-MM-DD')})
      .then(function(res){
        vm.requests = _.map(res, function(data){
          data.usuario = data.usuario_envia_info.nombre;
          data.origin = data.name_banks.pretty_banco_origin;
          data.receptor = data.name_banks.pretty_banco_receptor;
          return data;
        });
        vm.total = _.reduce(vm.requests, function(memo, data){
          if(data.tipo == 1){
            memo.deposito += parseFloat(data.monto);
          }
          if(data.tipo == 0){
            memo.retiro += parseFloat(data.monto);
          }
          return memo;
        },{retiro: 0, deposito: 0});
      })
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-desc';
        } else {
          return 'fa-sort-amount-asc';
        }
      } else {
        return 'fa-sort';
      }
    }


    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }


  }
})();
