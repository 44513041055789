angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider,$httpProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$state','$window', function ($q, user, $rootScope,$state, $window) {
      return {
        request: function(config) {
          config.headers['content-type'] = 'application/json';
          if(user.getCurrentUser()){
            config.headers['token'] = user.getCurrentUser().token;
          }

          return config;
        },
        responseError: function(rejection) {
          if (rejection.status == 401) {
            $rootScope.$broadcast('UNAUTHORIZED');
          }

          return $q.reject(rejection);
        }
      };
    }]);
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
  .state('webLayout', {
    abstract: true,
    component: 'webLayout'
  })
  .state('loginLayout', {
    abstract: true,
    component: 'loginLayout'
  })
  .state('login', {
    parent: 'loginLayout',
    url: '/login',
    component: 'auth'
  })
  .state('home', {
    parent: 'webLayout',
    url: '/',
    component: 'home',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('requestBalance', {
    parent: 'webLayout',
    url: '/solicitudes-de-saldo',
    component: 'requestBalance',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('withdrawBalance', {
    parent: 'webLayout',
    url: '/solicitudes-de-retiro',
    component: 'withdrawBalance',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('aproveAutomatic', {
    parent: 'webLayout',
    url: '/aprobaciones-automaticas',
    component: 'aproveAutomatic',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })

  
  .state('systemVersions', {
    parent: 'webLayout',
    url: '/versiones-del-sistema',
    component: 'systemVersions',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('accountsMovilPay', {
    parent: 'webLayout',
    url: '/bancos',
    component: 'accountsMovilPay',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('preapproved', {
    parent: 'webLayout',
    url: '/pre-aprobaciones-del-cajero',
    component: 'preapproved',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('vipclients', {
    parent: 'webLayout',
    url: '/configuracion-de-clientes-vip',
    component: 'vipclients',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('configCoupons', {
    parent: 'webLayout',
    url: '/configuracion-de-cupones',
    component: 'configCoupons',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('reportCoupons', {
    parent: 'webLayout',
    url: '/report-de-gastos-de-bonos',
    component: 'reportCoupons',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('contraloria', {
    parent: 'webLayout',
    url: '/verificacion-de-solicitudes',
    component: 'contraloria',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('defaultRequest', {
    parent: 'webLayout',
    url: '/configuracion-por-defecto-de-solicitudes-de-operaciones',
    component: 'defaultRequest',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('users', {
    parent: 'webLayout',
    url: '/usuarios',
    component: 'user',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('managmentAgent', {
    parent: 'webLayout',
    url: '/administrar-agentes',
    component: 'managmentAgent',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('newAgent', {
    parent: 'webLayout',
    url: '/administrar-agentes/nuevo-agente',
    component: 'newAgent',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('customers', {
    parent: 'webLayout',
    url: '/administrar-jugadores/:tipo_estructura?/:plays_today?',
    component: 'customers',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('reports', {
    parent: 'webLayout',
    url: '/reportes',
    component: 'reports',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('reportCajeroWebDetail', {
    parent: 'webLayout',
    url: '/reporte-cajer-web-creditos/:currency',
    component: 'reportCajeroWebDetail',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  
  .state('eNotifications', {
    parent: 'webLayout',
    url: '/configuracion-de-notificaciones-por-inactividad',
    component: 'emailNotifications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('couponsBank', {
    parent: 'webLayout',
    url: '/configuracion-de-banco-de-cupones',
    component: 'couponsBank',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('games', {
    parent: 'webLayout',
    url: '/administracion-de-taquillas',
    component: 'games',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('cintillo', {
    parent: 'webLayout',
    url: '/cintillo-de-publicidad',
    component: 'cintillo',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  });
}
