(function () {
  'use strict';
  angular
  .module('app')
  .factory('cashierService', cashierService);

  cashierService.$inject = ['$http', 'url', 'user', '$window', '$state'];

  function cashierService($http, url, $user, $window, $state) {

    function getRequests(user) {
      return $http.get(url.getApiUrl('solicitud_operacion'))
      .then(function(res){
        return res.data;
      })
    }

    function getMyRequests(tipo) {
      return $http.get(url.getApiUrl('solicitud_operacion?status=3&tipo='+tipo+'&usuario_id='+$user.getCurrentUser().id))
      .then(function(res){
        return res.data;
      })
    }

    function takeRequest(request_id){
      return $http.post(url.getApiUrl('solicitud_operacion/'+request_id+'/take_request'))
      .then(function(res){
        return res.data;
      })
    }

    function approveRequestBalance(request_id, data){
      return $http.post(url.getApiUrl('solicitud_operacion/'+request_id+'/approve_balance'), data)
      .then(function(res){
        return res.data;
      })
    }
    function approveRequestWhitdraw(request_id, data){
      return $http.post(url.getApiUrl('solicitud_operacion/'+request_id+'/approve_withdraw'), data)
      .then(function(res){
        return res.data;
      })
    }

    function rejectRequestBalance(request_id, data){
      return $http.post(url.getApiUrl('solicitud_operacion/'+request_id+'/reject_balance'), data)
      .then(function(res){
        return res.data;
      })
    }
    function rejectRequestWithdraw(request_id, data){
      return $http.post(url.getApiUrl('solicitud_operacion/'+request_id+'/reject_withdraw'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getSettingsDefault(){
      return $http.get(url.getApiUrl('settings_cajero_web'))
      .then(function(res){
        return res.data;
      })
    }

    function saveSettingsDefault(data){
      return $http.post(url.getApiUrl('settings_cajero_web'), data)
      .then(function(res){
        return res.data;
      })
    }
    function getNoVipClients(){
      return $http.get(url.getApiUrl('cajero_web/get_no_vip_clients'))
      .then(function(res){
        return res.data;
      })
    }

    function getVipClients(){
      return $http.get(url.getApiUrl('cajero_web/get_vip_clients'))
      .then(function(res){
        return res.data;
      })
    }

    function saveNewVipClients(data){
      return $http.post(url.getApiUrl('cajero_web/save_new_vip_clients'), data)
      .then(function(res){
        return res.data;
      })
    }

    function removeFromVip(id){

      return $http.get(url.getApiUrl('cajero_web/'+id+'/remove_from_vip'))
      .then(function(res){
        return res.data;
      })

    }

    function getAllEffecty(currency){
      return $http.get(url.getApiUrl('solicitud_operacion/effecty?currency='+currency))
      .then(function(res){
        return res.data;
      })
    }


    function updateCashierEffecty(data){
      return $http.post(url.getApiUrl('solicitud_operacion/update_effecty'), data)
      .then(function(res){
        return res.data;
      })
    }



    return {
      getRequests: getRequests,
      takeRequest: takeRequest,
      getMyRequests: getMyRequests,
      approveRequestBalance: approveRequestBalance,
      rejectRequestBalance: rejectRequestBalance,
      approveRequestWhitdraw: approveRequestWhitdraw,
      rejectRequestWithdraw: rejectRequestWithdraw,
      getSettingsDefault: getSettingsDefault,
      saveSettingsDefault: saveSettingsDefault,
      getVipClients: getVipClients,
      getNoVipClients: getNoVipClients,
      saveNewVipClients: saveNewVipClients,
      removeFromVip: removeFromVip,
      getAllEffecty: getAllEffecty,
      updateCashierEffecty: updateCashierEffecty
    };
  }

})();
