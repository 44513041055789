
(function(){
  'use strict';
  angular
  .module('app')
  .component('couponsBank', {
    templateUrl: 'app/components/coupons-bank/coupons-bank.html',
    controller: CouponsBankController
  });

  CouponsBankController.$inject = ['globalService','$window', '$uibModal','$rootScope']

  function CouponsBankController($globalService, $window, $uibModal, $rootScope) {
    var vm = this;
    vm.loading = false;


    vm.$onInit = function(){
      vm.loading = true;
      $globalService.getCouponsBank()
      .then(function(res){
        vm.loading = false;
        vm.couponBanks = res;
      })
      $globalService.getConfigCoupons()
      .then(function(res){
        vm.configCoupons = _.where(res, {config_type: 4, active: true});
      })
    }

    vm.newCouponBank = function(){
      if(vm.configCoupons.length > 0){
        var modal = $uibModal.open({
          animation: true,
          component: 'modalCouponBanks',
          size: 'lg',
          resolve: {
            configCoupons: function(){
              return vm.configCoupons;
            }
          }
        });
      }else{
        swal('Error','Debe existir una configuracion de tipo: Banco de cupones activa para crear los cupones','warning');
      }
    }

    vm.editCouponBank = function(data){
      if(vm.configCoupons.length > 0){
        var modal = $uibModal.open({
          animation: true,
          component: 'modalCouponBanks',
          size: 'lg',
          resolve: {
            configCoupons: function(){
              return vm.configCoupons;
            },
            couponsBank: function(){
              return angular.copy(data)
            }
          }
        });
      }else{
        swal('Error','Debe existir una configuracion de tipo: Banco de cupones activa para crear los cupones','warning');
      }
    }

    $rootScope.$on('couponsBank', function(event, data){
      vm.$onInit();
    })

  }

})();
