(function(){
  'use strict';

  angular
  .module('app')
  .component('modalEmailNotifications', {
    templateUrl: 'app/components/emails-notifications/modal-email-notifications.html',
    controller: ModalEmailsNotificationsController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalEmailsNotificationsController.$inject = ['toastr','_','$rootScope','$window','globalService','$uibModal'];

  function ModalEmailsNotificationsController(toastr, _, $rootScope,$window, $globalService, $uibModal) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.notification = vm.resolve.notification;

      $globalService.getCouponsBank()
      .then(function(res){
        vm.loading = false;
        vm.couponBanks = res;
        if(vm.notification.use_coupon && vm.notification.coupon_bank != null){
          vm.couponBankSelected = _.findWhere(vm.couponBanks, {id: vm.notification.coupon_bank});
        }
      })
    }

    vm.update = function(data){
      vm.loading = true;
      $globalService.updateNotificationtime(vm.notification)
      .then(function(res){
        vm.loading = false;
        $window.swal('Exito', res.message, 'success');
      }, function(err){
        vm.loading = false;
        $window.swal('Error', err.data.message, 'error');
      })
    }



  }
})();
