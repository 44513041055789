(function(){
  'use strict';
  angular
  .module('app')
  .component('webHeader', {
    templateUrl: 'app/components/web-header/web-header.html',
    controller: WebHeaderController
  });

  WebHeaderController.$inject = ['user','authService','$state'];

  function WebHeaderController($user, $authService, $state) {
    var vm = this;
    vm.doLogout = doLogout;
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
    }

    function doLogout() {
      if($authService.logout()){
        $state.go('login')
      }
    }
  }

})();
