(function(){
  'use strict';
  angular
  .module('app')
  .component('withdrawBalance', {
    templateUrl: 'app/components/balance-withdraw/balance-withdraw.html',
    controller: BalanceWithdrawController
  });

  BalanceWithdrawController.$inject = ['cashierService','toastr','$uibModal', '$window'];

  function BalanceWithdrawController($cashierService, toastr, $uibModal, $window) {
    var vm = this;
    vm.approveWithdraw = approveWithdraw;
    vm.rejectWithdraw = rejectWithdraw;

    vm.$onInit = function(){
      $cashierService.getMyRequests(0)
      .then(function(res){
        vm.requests = res.request;
        vm.requests = _.union(vm.request, res.requests_vips);
      })
    }

    function approveWithdraw(request){

      var modal = $uibModal.open({
        animation: true,
        component: 'modalRequests',
        size: 'lg',
        resolve: {
          type_request: function(){
            return 0;
          },
          request: function(){
            return angular.copy(request);
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }


    function rejectWithdraw(request_id){
      var data = {
        tipo: 3
      }
      swal({
        title: 'Esta seguro de cancelar la solicitud?',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Rechazar',
        showLoaderOnConfirm: true,
        preConfirm: function(reason) {
          data.detalle = reason
          return $cashierService.rejectRequestWithdraw(request_id, data)
          .then(function(res){
            return res;
          }, function(err){
            swal.showValidationError(err.data.message);
          })
        }
      }).then(function(result){
        if(result.value){
          toastr.success(result.value.message);
          vm.$onInit();
        }else{
          toastr.error('Accion cancelada');
        }
      })
    }

  }

})();
