angular
  .module('app')
  .component('managmentAgent', {
    templateUrl: 'app/components/manage-agents/agents.html',
    controller: ManageAgentsController
  });

  ManageAgentsController.$inject = ['$rootScope','$uibModal', 'toastr','globalService'];

function ManageAgentsController($rootScope, $uibModal, toastr, $globalService) {
  var vm = this;
  vm.managment = managment;
  vm.newAgent = newAgent;

   vm.$onInit = function(){
    $globalService.getNoVerifyAgents()
    .then(function(res){
      vm.estructuras = res;
    })
  }

  function managment(estructura){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalMangementAgents',
      size: 'lg',
      resolve: {
        estructura: function(){
          return angular.copy(estructura)
        }
      }
    });

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  function newAgent(estructura){
    var modal = $uibModal.open({
      animation: true,
      component: 'newAgent',
      size: 'lg',
    });

    modal.result.then(function(res){
      vm.$onInit();
    });
  }




  $rootScope.$on('estructuras', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

}
