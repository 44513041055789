(function() {
  'use strict';
  angular
    .module('app')
    .directive('usernameCheck', usernameCheck);

  usernameCheck.$inject = ['$http', '$q', 'globalService'];

  function usernameCheck($http, $q, globalService) {
    return {
      require: 'ngModel',
      scope: {
        usernameCheck: '=',
      },
      link: link,
    };

    function link(scope, el, attrs, ctrl) {
      ctrl.$asyncValidators.usernameCheck = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty model valid
          return $q.when();
        }
        var defer = $q.defer();
        var data = { username: modelValue };
        if(scope.usernameCheck) {
          data.id = scope.usernameCheck;
        }
          globalService.checkUserName(data)
          .then(function(res) {
            if (res.exist) {
              defer.reject();
            }
            defer.resolve();
          });
        return defer.promise;
      };
    }
  }
})();
