(function(){
  'use strict';

  angular
  .module('app')
  .component('modalSendEmailAllCustomers', {
    templateUrl: 'app/components/manage-customers/modal-send-email-all-customer.html',
    controller: ModalEmailAllCustomersController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalEmailAllCustomersController.$inject = ['toastr','globalService','_','$rootScope','user'];

  function ModalEmailAllCustomersController(toastr, $globalService, _, $rootScope, $user) {
    var vm = this;
    vm.loading = false;
    vm.use_coupon = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      
    }

    vm.send = function(){
      vm.loading = true;
      if(eval(vm.use_coupon)){
        if(vm.code_coupon != undefined){
          var data = {
            title: vm.title,
            text: vm.text,
            subject: vm.subject,
            use_coupon: true,
            generic_code: vm.couponBankSelected.generic_code,
          }
        }else{
          Swal('Error','El banco de cupones seleccionado no posee cupones','error');
          return;
        }
      }else{
        var data = {
          title: vm.title,
          text: vm.text,
          subject: vm.subject,
          use_coupon: false,
          generic_code: null,
        }
      }
      $globalService.sendMessageAllCustomers(data)
      .then(function(res){
        vm.loading = false;
        Swal('Exito',res.message,'success');
        vm.dismiss();
      })
    }

    vm.useCoupon = function(){
      if(eval(vm.use_coupon)){
        vm.loading = true;
        $globalService.getCouponsBank()
        .then(function(res){
          vm.loading = false;
          vm.couponsBanks = res;
        })
      }
    }

    vm.selectCouponbank = function(){
      vm.coupon_bank = vm.couponBankSelected.id;
      vm.code_coupon = _.findWhere(vm.couponBankSelected.codes_coupon_bank, {used: false, sent: false});
    }





  }
})();
