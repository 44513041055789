(function(){
  'use strict';
  angular
  .module('app')
  .component('reports', {
    templateUrl: 'app/components/reports/reports.html',
    controller: ReportController
  });

  ReportController.$inject = ['toastr','$window','reportsService','$rootScope'];

  function ReportController(toastr, $window, $reportsService, $rootScope) {
    var vm = this;
    vm.getReport = getReport;

    vm.$onInit = function(){
      getReport()
    }

    function getReport(){
      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD')
      }

      $reportsService.getReportSales(data)
      .then(function(res){
        vm.resultReports = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.total = _.reduce(vm.resultReports, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, '','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, '','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, '','2','.',',');
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, '','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, '','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});

      });

    }






    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    $rootScope.$emit('webLayout', {data: {title: 'Reportes',subtitle: 'Reporte de tickets',previewPage: 'Dashboard', currentPage:'Premiacion'}});
  }



})();
