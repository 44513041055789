(function(){
  'use strict';

  angular
  .module('app')
  .component('modalCoupons', {
    templateUrl: 'app/components/config-coupons/modal-coupon.html',
    controller: ModalCouponController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCouponController.$inject = ['toastr','_','$rootScope','user','globalService'];

  function ModalCouponController(toastr, _, $rootScope,$user, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;
    vm.open = {
      open1: false,
      open2: false
    }


    vm.$onInit = function(){
      $globalService.getCouponsTypes()
      .then(function(res){
        vm.configTypes = res;
        if(vm.resolve.configCoupon){
          vm.configCoupon = vm.resolve.configCoupon;
          vm.configTypeSelected = _.findWhere(vm.configTypes, {id: vm.configCoupon.config_type});
          vm.configCoupon.desde = new Date(moment(vm.resolve.configCoupon.desde));
          vm.configCoupon.hasta = new Date(moment(vm.resolve.configCoupon.hasta));
        }else{
          vm.configCoupon = {es_monto: true, active: true};
        }
      })
    }

    function save() {
      if(vm.configCoupon.id){
        $globalService.updateConfigCoupons(vm.configCoupon.id, vm.configCoupon)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          $rootScope.$emit('configCoupons',{});
          vm.close();
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        })
      }else{
        $globalService.saveConfigCoupons(vm.configCoupon)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          $rootScope.$emit('configCoupons',{});
          vm.close();
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        })
      }

    }


    vm.openCalendar = function(e) {
      e.preventDefault();
      e.stopPropagation();
      vm.open.open1 = true;
    };

    vm.openCalendar2 = function(e) {
      e.preventDefault();
      e.stopPropagation();
      vm.open.open2 = true;
    };

    function cancel() {
      vm.dismiss();
    }
  }
})();
