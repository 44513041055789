(function(){
  'use strict';

  angular
  .module('app')
  .component('modalRequests', {
    templateUrl: 'app/components/modals/accept-request.html',
    controller: ModalAcceptRequest,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalAcceptRequest.$inject = ['toastr','_','$rootScope','user','cashierService','$window','$uibModal','globalService'];

  function ModalAcceptRequest(toastr, _, $rootScope,$user, $cashierService, $window, $uibModal, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.viewImage = viewImage;

    vm.$onInit = function(){
      vm.request = vm.resolve.request;
      vm.type_request = vm.resolve.type_request;
      // 0 => Retiro, 1 => Deposito
      if(vm.type_request == 0){
        $globalService.getBancos()
        .then(function(bancos){
          vm.bancos = bancos;
        })

      }
    }

    function save() {
      if(vm.type_request == 1){
        var data = {
          tipo: 1
        }
        swal({
          title: 'Esta seguro de aprobar esta solicitud?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aprobar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $cashierService.approveRequestBalance(vm.request.id, data)
              .then(function(res){
                resolve();
                toastr.success(res.message);
                vm.close();
              }, function(err){
                toastr.error(err.data.message);
              })
            });
          },
          allowOutsideClick: false,
        })
      }else{
        vm.request.status = 1;
        vm.request.banco_origin = vm.bankOrigenSelected.id;
        swal({
          title: 'Esta seguro de aprobar esta solicitud?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aprobar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $cashierService.approveRequestWhitdraw(vm.request.id, vm.request)
              .then(function(res){
                resolve();
                toastr.success(res.message);
                vm.$onInit();
                vm.close();
              }, function(err){
                toastr.error(err.data.message);
              })
            });
          },
          allowOutsideClick: false,
        })
      }

    }

    function viewImage(){
      if(vm.request.file.url != null){
        var modal = $uibModal.open({
          animation: true,
          component: 'modalImage',
          size: 'lg',
          resolve: {
            request: function(){
              return vm.request;
            }
          }
        });

        modal.result.then(function(res){
          vm.$onInit();
        }, function(err){

        });
      }else{
        $window.swal('Esta solicitud no posee imagen','','info');
      }
    }
  }
})();
